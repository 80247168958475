import React from 'react';
import { Typography, Box } from '@mui/material';

function Header() {
    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            style={{ backgroundColor: 'white' }}
            padding="20px"
        >
            <img src="/images/lee-projects-logo.png" alt="Lee Projects" />
            <Typography style={{ color: 'black', fontSize: '0.9em' }}>
                Tradition Meets Innovation
            </Typography>
            <hr />
        </Box>
    );
}

export default Header;
