import React from 'react';
import { Typography, Box } from '@mui/material';

function Legacy() {
    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            style={{ backgroundColor: '#2284C6', borderRadius: '10px', padding: '20px', margin: '20px 0' }}
        >
            <Typography variant="h4" gutterBottom style={{ color: 'white' }}>
                Our Legacy
            </Typography>
            <Typography paragraph style={{ color: 'white' }}>
                At Lee Projects, we are deeply rooted in our traditions. For over 50 years, we've been 
                committed to delivering top-quality projects that stand the test of time.
            </Typography>
            <Typography paragraph style={{ color: 'white' }}>
                From urban infrastructures to commercial real estates, our legacy projects have always 
                been a beacon of excellence and craftsmanship.
            </Typography>
            <Typography style={{ color: '#66B2FF' }}>
                Explore our Legacy Projects
            </Typography>
        </Box>
    );
}

export default Legacy;
