import React from 'react';
import { Typography, Link, Box } from '@mui/material';

function Contact() {
    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            style={{ backgroundColor: '#2284C6', borderRadius: '10px', padding: '20px', margin: '20px 0' }}
        >
            <Typography variant="h4" gutterBottom style={{ color: 'white' }}>
                Get in Touch
            </Typography>
            <Typography paragraph style={{ color: 'white' }}>
                Have any queries or looking to collaborate? Our team would be delighted to hear from you.
            </Typography>
            <Typography style={{ color: 'white' }}>
                <strong>John Lee, Director:</strong> <Link href="mailto:john@lee-projects.com" style={{ color: '#66B2FF' }}>john@lee-projects.com</Link>
            </Typography>
            <Typography style={{ color: 'white' }}>
                <strong>Ethan Lee, Chief Technology Officer:</strong> <Link href="mailto:Ethan@lee-projects.com" style={{ color: '#66B2FF' }}>ethan@lee-projects.com</Link>
            </Typography>
        </Box>
    );
}

export default Contact;
