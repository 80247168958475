import React from 'react';
import { CssBaseline, Container, ThemeProvider, createTheme } from '@mui/material';
import Header from './components/Header';
import Legacy from './components/Legacy';
import Future from './components/Future';
import Contact from './components/Contact';
import './components/animations.css';

const theme = createTheme({
    palette: {
        background: {
            default: 'white'
        }
    }
});

function App() {
    return (
        <ThemeProvider theme={theme}>
            <div className="swipe-animation"></div>
            <CssBaseline />
            <div style={{ minHeight: '100vh', backgroundColor: 'white' }}>
                <Container>
                    <Header />
                    <Legacy />
                    <Future />
                    <Contact />
                </Container>
            </div>
        </ThemeProvider>
    );
}


export default App;
