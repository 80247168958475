import React from 'react';
import { Typography, Box } from '@mui/material';

function Future() {
    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            style={{ backgroundColor: '#2284C6', borderRadius: '10px', padding: '20px', margin: '20px 0' }}
        >
            <Typography variant="h4" gutterBottom style={{ color: 'white' }}>
                The Future
            </Typography>
            <Typography paragraph style={{ color: 'white' }}>
                At Lee Projects, we aren't just resting on our laurels. We are constantly innovating and 
                adapting to the changing needs of our clients and the industry.
            </Typography>
            <Typography paragraph style={{ color: 'white' }}>
                With a keen eye on sustainable development and modern architectural practices, we're 
                spearheading the next generation of construction projects.
            </Typography>
            <Typography style={{ color: '#66B2FF' }}>
                Discover what's next
            </Typography>
        </Box>
    );
}

export default Future;
